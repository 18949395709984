var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Department"},[_c('OrganizationMenu'),_c('div',{staticClass:"wrap"},[_c('el-form',{attrs:{"inline":true}},[_c('el-form-item',{attrs:{"label":"项目名称"}},[_c('el-select',{attrs:{"size":"small","placeholder":"请选择项目"},model:{value:(_vm.currentProject),callback:function ($$v) {_vm.currentProject=$$v},expression:"currentProject"}},_vm._l((_vm.projectList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.proName,"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"label":"参建单位"}},[_c('el-select',{attrs:{"size":"small","placeholder":"请选择参建单位","clearable":""},model:{value:(_vm.currentUnit),callback:function ($$v) {_vm.currentUnit=$$v},expression:"currentUnit"}},_vm._l((_vm.unitList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"label":"部门/班组名称"}},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入部门/班组名称","clearable":""},model:{value:(_vm.departmentName),callback:function ($$v) {_vm.departmentName=$$v},expression:"departmentName"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"size":"small","icon":"el-icon-search"},on:{"click":_vm.getDepartmentList}},[_vm._v("查询")])],1)],1)],1),_c('div',{staticClass:"wrap-blank"},[_c('el-button',{attrs:{"size":"small","plain":"","type":"primary","icon":"el-icon-plus"},on:{"click":function($event){return _vm.$router.push({
          name: 'DepartmentInfo',
          query: {
            action: 'add',
            projectId: _vm.currentProject,
            deptId: _vm.currentUnit,
            departmentId: null
          },
        })}}},[_vm._v("添加")]),_c('el-button',{attrs:{"type":"danger","icon":"el-icon-delete","size":"small","plain":""},on:{"click":_vm.delDepartment}},[_vm._v("删除")])],1),_c('div',{staticClass:"wrap"},[_vm._l((_vm.departmentList),function(item,index){return _c('div',{key:index,staticClass:"unit-item"},[_c('el-radio',{attrs:{"label":index},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},[_vm._v(_vm._s(""))]),_c('el-descriptions',[_c('template',{slot:"title"},[_c('a',{on:{"click":function($event){return _vm.$router.push({
                name: 'DepartmentInfo',
                query: {
                  action: 'read',
                  projectId: _vm.currentProject,
                  deptId: _vm.currentUnit,
                  departmentId: item.id
                },
              })}}},[_vm._v(_vm._s(item.labourGroupName))])]),_c('el-descriptions-item',{attrs:{"label":"部门/班组负责人"}},[_vm._v(_vm._s(item.leader))]),_c('el-descriptions-item',{attrs:{"label":"所属项目"}},[_vm._v(_vm._s(item.projectId))]),_c('el-descriptions-item',{attrs:{"label":"进场时间"}},[_vm._v(_vm._s(item.approachTime))]),_c('el-descriptions-item',{attrs:{"label":"负责人电话"}},[_vm._v(_vm._s(item.phone))]),_c('el-descriptions-item',{attrs:{"label":"所属参建单位"}},[_vm._v(_vm._s(item.deptId))]),_c('el-descriptions-item',{attrs:{"label":"退场时间"}},[_vm._v(_vm._s(item.outTime))]),_c('el-descriptions-item',{attrs:{"label":"负责人证件号"}},[_vm._v(_vm._s(item.leaderCard))])],2)],1)}),(_vm.departmentList.length)?_c('el-pagination',{attrs:{"layout":"total, sizes, prev, pager, next","page-size":_vm.pageSize,"current-page":_vm.pageNum,"page-sizes":[2, 5, 10, 20, 50],"total":_vm.totals},on:{"update:pageSize":function($event){_vm.pageSize=$event},"update:page-size":function($event){_vm.pageSize=$event},"update:currentPage":function($event){_vm.pageNum=$event},"update:current-page":function($event){_vm.pageNum=$event},"current-change":_vm.getDepartmentList,"size-change":_vm.getDepartmentList}}):_vm._e(),(!_vm.departmentList.length)?_c('el-empty',{attrs:{"description":"暂无数据"}}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }