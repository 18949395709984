<template>
  <div class="Department">
    <OrganizationMenu />
    <!-- 筛选项 -->
    <div class="wrap">
      <el-form :inline="true">
        <el-form-item label="项目名称">
          <el-select
            size="small"
            v-model="currentProject"
            placeholder="请选择项目"
          >
            <el-option
              v-for="item in projectList"
              :key="item.id"
              :label="item.proName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="参建单位">
          <el-select
            size="small"
            v-model="currentUnit"
            placeholder="请选择参建单位"
            clearable
          >
            <el-option
              v-for="item in unitList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="部门/班组名称">
          <el-input
            size="small"
            v-model="departmentName"
            placeholder="请输入部门/班组名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            size="small"
            icon="el-icon-search"
            @click="getDepartmentList"
          >查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 功能按钮 -->
    <div class="wrap-blank">
      <el-button
        size="small"
        plain
        type="primary"
        icon="el-icon-plus"
        @click="
          $router.push({
            name: 'DepartmentInfo',
            query: {
              action: 'add',
              projectId: currentProject,
              deptId: currentUnit,
              departmentId: null
            },
          })
        "
        >添加</el-button
      >
      <el-button
        type="danger"
        icon="el-icon-delete"
        size="small"
        plain
        @click="delDepartment"
        >删除</el-button
      >
      <!-- <el-button
        type="success"
        size="small"
        plain
        icon="el-icon-download"
        >导出当前数据</el-button
      >
      <el-button
        type="success"
        size="small"
        plain
        icon="el-icon-download"
        >导出全部数据</el-button
      > -->
    </div>

    <!-- 数据列表 -->
    <div class="wrap">
      <div class="unit-item" v-for="(item, index) in departmentList" :key="index">
        <el-radio v-model="radio" :label="index">{{ "" }}</el-radio>
        <el-descriptions>
          <template slot="title">
            <a
              @click="
                $router.push({
                  name: 'DepartmentInfo',
                  query: {
                    action: 'read',
                    projectId: currentProject,
                    deptId: currentUnit,
                    departmentId: item.id
                  },
                })
              "
              >{{ item.labourGroupName }}</a
            >
          </template>
          <el-descriptions-item label="部门/班组负责人">{{
            item.leader
          }}</el-descriptions-item>
          <el-descriptions-item label="所属项目">{{
            item.projectId
          }}</el-descriptions-item>
          <el-descriptions-item label="进场时间">{{
            item.approachTime
          }}</el-descriptions-item>
          <el-descriptions-item label="负责人电话">{{
            item.phone
          }}</el-descriptions-item>
          <el-descriptions-item label="所属参建单位">{{
            item.deptId
          }}</el-descriptions-item>
          <el-descriptions-item label="退场时间">{{
            item.outTime
          }}</el-descriptions-item>
          <el-descriptions-item label="负责人证件号">{{
            item.leaderCard
          }}</el-descriptions-item>
        </el-descriptions>
      </div>

      <el-pagination
        v-if="departmentList.length"
        layout="total, sizes, prev, pager, next"
        :page-size.sync="pageSize"
        :current-page.sync="pageNum"
        :page-sizes="[2, 5, 10, 20, 50]"
        :total="totals"
        @current-change="getDepartmentList"
        @size-change="getDepartmentList"
      >
      </el-pagination>

      <el-empty description="暂无数据" v-if="!departmentList.length"></el-empty>
    </div>
  </div>
</template>

<style lang="less" scope>
.Department {
  .el-form-item {
    margin-bottom: 0;
  }
  .unit-item {
    margin-bottom: 20px;
    border-bottom: 1px dashed #d5d9e4;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>

<script>
import OrganizationMenu from "@/components/OrganizationMenu.vue";

export default {
  name: "Department",
  components: {
    OrganizationMenu,
  },
  data() {
    return {
      currentProject: null,
      currentUnit: null,
      departmentName: null,

      projectList: [],
      unitList: [],
      departmentList: [],

      radio: null,

      pageSize: 5,
      pageNum: 1,
      totals: 0,

      loading: null
    };
  },
  computed: {
    projectId: function () {
      return this.$route.query.projectId;
    },
    deptId: function () {
      return this.$route.query.deptId;
    },
  },
  mounted() {
    this.getProjectList() // 加载项目列表数据
    this.setInitData() // 获取初始化路由参数
  },
  watch: {
    currentProject: function (val) {
      this.getUnitList() // 加载单位列表数据
      this.getDepartmentList() // 加载部门列表
      this.currentUnit = null
    },
    currentUnit: function (val) {
      this.getDepartmentList() // 加载部门列表
    }
  },
  methods: {
    setInitData: function () {
      var that = this
      setTimeout(() => {
        console.log('路由参数 deptId =>', that.deptId)
        if (that.deptId) {
          that.currentUnit = that.deptId
        }
        console.log('路由参数 projectId =>', that.projectId)
        if (that.projectId) {
          that.currentProject = that.projectId
        }
      }, 1000);
    },
    // 加载项目列表数据
    getProjectList: function () {
      var that = this;
      that.radio = null;
      that.currentUnit = null;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        proName: "",
        projectSysNo: "",
        proAddress: "",
        pageNum: 1,
        pageSize: 10,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/project/projectSearch",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.projectList = res.data.projectList;
          if (res.data.projectList.length) {
            that.currentProject = res.data.projectList[0].id
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 加载单位列表数据
    getUnitList: function () {
      var that = this;
      // that.radio = null;
      var data = {
        companyType: "",
        companyName: "",
        address: "",
        projectId: that.currentProject,
        pageNum: 1,
        pageSize: "",
      }
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/dept/InquireCompany",
          data: data
        })
        .then((res) => {
          console.log(res);
          that.unitList = res.data.depts;
          // if (res.data.depts.length) {
          //   that.currentUnit = res.data.depts[0].id
          // }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 获取部门/班组列表数据
    getDepartmentList: function () {
      var that = this;
      // that.radio = null
      var data = {
        labourGroupName: that.departmentName,
        projectId: that.currentProject,
        deptId: that.currentUnit,
        pageNum: that.pageNum,
        pageSize: that.pageSize,
      }
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/labourgroup/labourGroupSearch",
          data: data
        })
        .then((res) => {
          console.log('getDepartmentList =>', res);
          that.totals = res.data.totals
          that.departmentList = res.data.labourGroupList;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 根据 id 删除部门/班组
    delDepartment: function () {
      var that = this;
      if (that.radio == null) {
        that.$alert("请先选择要删除的部门/班组", "提示", { showClose: false });
        return;
      }
      var department = that.departmentList[that.radio];
      that
        .$confirm(`此操作将删除 ${department.labourGroupName}, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          var data = {
             id: department.id,
          };
          that.loading = that.$loading({
            lock: true,
            text: "Loading...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          that
            .$axiosAdmin({
              method: "post",
              url: "api/admin/labourgroup/deleteLabourGroup",
              data: JSON.stringify(data),
            })
            .then((res) => {
              that.loading.close();
              console.log("成功=>", res);
              if (res.code == 0) {
                that.$notify({
                  title: "操作成功",
                  message: "已成功删除部门/班组",
                  type: "success",
                });
                that.getDepartmentList();
              } else {
                that.$notify({
                  title: "警告",
                  message: "部门/班组删除失败",
                  type: "warning",
                });
              }
            })
            .catch((err) => {
              console.log("失败=>", err);
              that.loading.close();
            });
        })
        .catch(() => {
          that.$notify({
            title: "警告",
            message: "已取消删除，请谨慎操作。",
            type: "warning",
          });
        });
    },
  },
};
</script>